import TeamCreateModal from '@modals/TeamCreate'

export default {
  methods: {
    showTeamCreateModal () {
      this.$bus.$emit('overlay:modal', {
        component: TeamCreateModal,
        size: 'sm'
      })
    }
  }
}
