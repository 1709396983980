<template>
  <div class="flex flex-col items-center h-full p-2 pt-0">
    <!-- Team List -->
    <div
      v-if="user"
      class="select-none"
    >
      <AppSidebarTeams />
      <div class="block mx-2 my-4">
        <a
          v-tooltip.bottom-end="'Create New Team'"
          href="#"
          class="flex justify-center items-center rounded cursor-pointer h-10 w-10 text-white font-bold hover:bg-gray-600"
          @click.prevent="showTeamCreateModal"
        >
          <fa-icon
            icon="plus"
            size="sm"
            class="fa-fw"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import teamCreate from '@modals/mixins/team-create.js'
const AppSidebarTeams = () => import('./AppSidebarTeams')

export default {
  name: 'AppSidebar',

  components: {
    AppSidebarTeams
  },

  mixins: [teamCreate],

  computed: {
    user () {
      return this.$store.getters.user
    }
  }
}

</script>
