<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Create New Team
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form @submit.prevent="onSubmit">
        <div class="">
          <label
            for="teamName"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            New Team Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="teamName"
              ref="nameinput"
              v-model="name"
              tabindex="0"
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
              autofocus
              type="text"
              placeholder=""
            >
          </div>
        </div>

        <div
          v-show="form.errors.has('name')"
          class="mt-6 ui message error"
        >
          {{ form.errors.get('name') }}
        </div>

        <div class="mt-6 btn-group flex items-center justify-end">
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn primary"
            :class="{ 'loading': form.busy }"
            @click.prevent="onSubmit"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

  name: 'TeamCreateModal',

  data () {
    return {
      name: '',
      form: new SparkForm({})
    }
  },

  mounted () {
    this.$refs.nameinput.focus()
  },

  methods: {

    onSubmit () {
      if (this.name === '') {
        this.form.setErrors({ form: ['Check the form details and try again'] })
      } else {
        this.handleSubmit()
      }
    },

    handleSubmit () {
      this.$store
        .dispatch('teams/createTeam', {
          name: this.name
        })
        .then((team) => {
          alert.success('Team Created')
          this.$router.push('/team/' + team.slug)
          this.$emit('close')
        })
        .catch(error => {
          this.form.setErrors(error)
        })
    }
  }
}
</script>
